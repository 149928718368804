<template>
    <div :class="['product-rating', (store.state.hide_price_for_guest && !store.state.is_authenticated) ? 'd-flex': '']">
        <div class="back-stars">
            <i class="las la-star"></i>
            <i class="las la-star"></i>
            <i class="las la-star"></i>
            <i class="las la-star"></i>
            <i class="las la-star"></i>

            <div class="front-stars" :style="{ width: ratingPercent + '%' }">
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
            </div>
        </div>

        <span class="rating-count" v-if="hasReviewCount">({{ this.reviewCount }})</span>
    </div>
</template>

<script>
import store from '../store'

    export default {
        data() {
            return {
                store: store
            }
        },

        props: ['ratingPercent', 'reviewCount'],

        computed: {
            hasReviewCount() {
                return this.reviewCount !== undefined;
            },
        },
    };
</script>
