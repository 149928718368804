<template>
    <section class="featured-categories-wrap mb-5">

        <div class="container">
                <ul class="tab-content featured-categories-tabs d-block  featured-categories-slick">
                    <li
                        v-for="(tab, index) in data"
                        :key="index"
                        @click="redirect(tab)"
                        class="tab-item  mt-5"
                    >
                        <div v-if="!tab.icon" class="featured-category-image">
                            <img :src="logo(tab.logo)" :class="{ 'image-placeholder': ! hasLogo(tab.logo) }" alt="category logo">
                        </div>

                        <div v-else>
                            <i :class="[tab.icon, 'icon-category']"></i>
                        </div>

                        <span class="featured-category-name">{{ tab.name }}</span>
                    </li>
                </ul>
        </div>

    </section>
</template>

<script>
    import DynamicCategoriesMixin from '../../mixins/DynamicCategoriesMixin';
    import { slickPrevArrow, slickNextArrow } from '../../functions';
    export default {
        props: ['data'],
        mixins: [
        DynamicCategoriesMixin,
        ],
        mounted() {
            console.log(this.data);
        },

        methods: {
            hasLogo(tab) {
                return tab.id;
            },

            logo(tab) {
                if (this.hasLogo(tab)) {
                    return tab.path;
                }

                return `${window.FleetCart.baseUrl}/themes/storefront/public/images/image-placeholder.png`;
            },

            redirect(tab) {
                if(tab.link) {
                    window.location.href = tab.link;
                    return;
                }

                window.location.href = tab.url
            },

            selector() {
                return $('.featured-categories-slick');
            },

            slickOptions() {
                return {
                    rows: 0,
                    dots: true,
                    arrows: false,
                    infinite: true,
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    rtl: window.FleetCart.rtl,
                    prevArrow: slickPrevArrow(),
                    nextArrow: slickNextArrow(),
                    responsive: [
                        {
                            breakpoint: 1761,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 6,
                            },
                        },
                        {
                            breakpoint: 1301,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 6,
                            },
                        },
                        {
                            breakpoint: 1051,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 6,
                            },
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4,
                            },
                        },
                        {
                            breakpoint: 881,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            },
                        },
                        {
                            breakpoint: 641,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            },
                        },
                    ],
                };
            },
        },
    };
</script>
